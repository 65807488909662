module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The E Club! The Mario, Star Trek, and Science Website!","short_name":"The E Club!","start_url":"/","icon":"src/design/logo.svg","background_color":"#FFF","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"0024f51b48884648cbcdeae6e045cc2a"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"withWebp":true,"backgroundColor":"none","disableBgImage":true,"linkImagesToOriginal":false,"wrapperStyle":"margin: 0","maxWidth":650,"showCaptions":false,"markdownCaptions":false,"quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
